import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { IRA_ADMIN } from '../../../Api/panelApis';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import PdfImage from '../../../components/DocumentImage/pdfImage';
import PdfPreview from '../../../components/DocumentImage/pdfPreview';
import EditableFiles from '../../../components/InputFields/EditFiles';
import { PDF_MESSAGE } from '../../../Constant/IRAPanelData';
import { toastError, toastSuccess } from '../../../Utils/helper';
import EditableInput from '../../../components/InputFields/EditableInput';

const EditAccreditationDocs = ({ values, documentFileType, setdocumentFileType, convertBase64 }: any) => {
  const [documentFileTypeURL, setdocumentFileTypeURL] = React.useState('');
  const [imageTitle, setImageTitle] = React.useState('');
  const [fileNameError, setFileNameError] = React.useState<string>('');
  const [fileId, setFileId] = React.useState('');
  const [loading, setloading] = React.useState(false);
  const navigate = useNavigate();

  const submitAccreditationDoc = async () => {
    setloading(true);
    const data = {
      image: documentFileType,
      imageTitle: imageTitle,
    };
    try {
      const response = await IRA_ADMIN.accreditedDocument(data, fileId);
      if (response.success) {
        toastSuccess(response.message);
        setloading(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error: any) {
      toastError(error.response.data.error);
      setloading(false);
    }
  };

  const handleDocumentNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 30) {
      setImageTitle(e.target.value);
      setFileNameError('');
    } else {
      setFileNameError('Must be 30 characters or less*');
    }
  };
  const handleFocusChange = (e: any) => {
    const { value } = e.target;
    if (value.length <= 30) {
      setFileNameError('');
    }
  };

  const handleClick = () => {
    console.log('Clicked');
  };

  const fileExtension = (fileName: any) => fileName.image_url.split('.').pop();

  return (
    <div>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-sm text-blue-600 font-medium mt-4 heading'>ACCREDITATED DOCUMENT</h2>
      </div>
      <hr className='hrLine' />
      {documentFileType && (
        <div className='mt-6 px-6'>
          <div className='alignmentAround'>
            <EditableInput
              heading='Document Title:'
              type='text'
              value={imageTitle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDocumentNameChange(e)}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleFocusChange(e)}
              className='inputStyle'
              name='documentName'
              error={fileNameError}
            />
          </div>
        </div>
      )}
      <div className='mt-6 px-6'>
        <div className='alignmentAround flex-wrap'>
          <div className='flex-col alignment'>
            {documentFileTypeURL ? (
              documentFileType?.type === 'application/pdf' ? (
                <>
                  <p>DOCUMENT IMAGE</p>
                  <p className='mb-6'>{PDF_MESSAGE}</p>
                  <PdfImage title={documentFileType?.name} />
                </>
              ) : (
                <>
                  {documentFileType ? (
                    <>
                      <p>DOCUMENT IMAGE</p>
                      <p>{PDF_MESSAGE}</p>
                      <DocumentImage src={documentFileTypeURL} />
                    </>
                  ) : (
                    <EditableFiles
                      type='file'
                      imageUrl={documentFileType}
                      onChange={(e: any) => {
                        convertBase64(e.target.files[0], setdocumentFileTypeURL);
                        setdocumentFileType(e.target.files[0]);
                      }}
                      name='documentFile'
                      placeholder={PDF_MESSAGE}
                      title={'Upload'}
                      parentStyle={'bg-grey h-52 p-5 rounded-lg'}
                      uploadButtonStyle={'bg-pink'}
                      docTypes={'application/pdf'}
                      onclick={handleClick}
                    />
                  )}
                </>
              )
            ) : values.AccreditedDocument?.length > 0 ? (
              <>
                <p>DOCUMENT IMAGE</p>
                <p className='mb-3'>{PDF_MESSAGE}</p>
                <div className='flex flex-wrap'>
                  {values.AccreditedDocument.map((file: any, index: number) => (
                    <div key={index}>
                      {fileExtension(file) === 'pdf' ? (
                        <PdfPreview url={file.image_url} fileName={file.image_title} />
                      ) : (
                        <DocumentImage src={file.image_url} fileName={file.image_title} />
                      )}
                      <EditableFiles
                        type='file'
                        imageUrl={documentFileType}
                        onChange={(e: any) => {
                          convertBase64(e.target.files[0], setdocumentFileTypeURL);
                          setdocumentFileType(e.target.files[0]);
                        }}
                        name='documentFile'
                        placeholder={
                          documentFileTypeURL || values.AccreditedDocument?.length > 0
                            ? null
                            : '(You can upload an image file or a pdf document)'
                        }
                        title={documentFileTypeURL || values.AccreditedDocument?.length > 0 ? 'Change Document' : 'Upload'}
                        parentStyle={documentFileTypeURL || values.AccreditedDocument?.length > 0 ? null : 'bg-grey h-52 p-5 rounded-lg'}
                        uploadButtonStyle={
                          documentFileTypeURL || values.AccreditedDocument?.length > 0 ? 'bg-transparent text-darkblue  w-40' : 'bg-pink'
                        }
                        docTypes={'application/pdf'}
                        // onClick to store fileId and file.imageTitle in state
                        onclick={() => {
                          setFileId(file.id); // Store file id
                          setImageTitle(file.image_title); // Store file imageTitle
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className='flex justify-center mt-0'>
          <button
            onClick={submitAccreditationDoc}
            className='loadingBtn w-40 m-1'
            disabled={!documentFileTypeURL || !documentFileType || loading}
          >
            {loading ? <ThreeDots height={20} width={30} color='white' ariaLabel='loading-indicator' /> : 'Update Document'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditAccreditationDocs;
